<template>
  <div>
    <h3 class="mb-4 text-m-lg md:text-lg">
      {{ title || $t("footer.newsletter.header") }}
    </h3>

    <div v-show="formState === NewsletterFormState.DEFAULT">
      <div v-if="showText" class="text-m-md md:text-md">
        {{ $t("footer.newsletter.text") }}
      </div>

      <form class="mt-4" @submit.prevent="onSubmit">
        <Input
          v-model="formValues.email"
          :label="$t('footer.newsletter.label.email')"
          :id="fieldNames['email']"
          :name="fieldNames['email']"
          type="email"
          :is-error="v$.email.$errors?.length > 0"
          :support-text="v$.email.$errors?.[0]?.$message as string"
          @blur="v$.email.$touch()"
        />

        <Transition
          enter-from-class="opacity-0"
          enter-to-class="opacity-100"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
          enter-active-class="transition-opacity duration-300"
          leave-active-class="transition-opacity duration-300"
        >
          <div v-if="computedMainInputFocused || showAllFieldsOnInit">
            <div class="mt-4">
              <Input
                v-model="formValues.firstName"
                :id="fieldNames['firstName']"
                :name="fieldNames['firstName']"
                :label="$t('footer.newsletter.label.firstName')"
                :is-error="v$.firstName.$errors?.length > 0"
                :support-text="v$.firstName.$errors?.[0]?.$message as string"
                @blur="v$.firstName.$touch()"
              />
            </div>
            <div class="mt-4">
              <Input
                v-model="formValues.lastName"
                :id="fieldNames['lastName']"
                :name="fieldNames['lastName']"
                :label="$t('footer.newsletter.label.lastName')"
                :is-error="v$.lastName.$errors?.length > 0"
                :support-text="v$.lastName.$errors?.[0]?.$message as string"
                @blur="v$.lastName.$touch()"
              />
            </div>

            <div class="mt-4">
              <Checkbox
                v-model="formValues.acceptedTerms"
                :label="$t('footer.newsletter.label.checkbox')"
                :is-error="v$.acceptedTerms.$errors?.length > 0"
                :support-text="
                  v$.acceptedTerms.$errors?.[0]?.$message as string
                "
              ></Checkbox>
            </div>

            <div class="mt-4 text-m-sm md:text-sm">
              {{ $t("footer.newsletter.required") }}
            </div>

            <Button class="mt-4 w-full md:max-w-[330px]">
              {{ $t("footer.newsletter.subscribeBtn") }}
            </Button>
          </div>
        </Transition>
      </form>
    </div>

    <FeedbackBanner
      v-show="formState === NewsletterFormState.SUBSCRIBED"
      class="text-validation-green"
    >
      {{ $t("footer.nerwsletter.subscribedFeedback") }}
    </FeedbackBanner>
  </div>
</template>

<script setup lang="ts">
import { reactive, computed } from "vue";
import Input from "@/components/atoms/Input";
import Checkbox from "@/components/atoms/Checkbox";
import Button from "@/components/atoms/Button";
import FeedbackBanner from "@/components/atoms/FeedbackBanner";
import { NewsletterFormState } from "@/types";
import { useMounted } from "@vueuse/core";

import { useVuelidate } from "@vuelidate/core";
import { formNewsletterValidationRules } from "@/validation-rules/formNewsletter";

import { fieldNames } from "@/constants/fieldNames";

withDefaults(
  defineProps<{
    formState: NewsletterFormState;
    showAllFieldsOnInit?: boolean;
    title?: string;
    showText?: boolean;
  }>(),
  {
    title: "",
    showText: true,
  },
);

const emit = defineEmits(["newsletterFormSubmit"]);

const formValues = reactive({
  email: "",
  firstName: "",
  lastName: "",
  acceptedTerms: false,
});
const v$ = useVuelidate(formNewsletterValidationRules(true), formValues);

const isMounted = useMounted();
const computedMainInputFocused = computed(() => {
  if (!isMounted.value) return false;
  return formValues.email.length > 0;
});

const onSubmit = async () => {
  v$.value.$validate();

  const hasFormErrors = v$.value.$errors.length > 0;

  if (!hasFormErrors) {
    emit("newsletterFormSubmit", formValues);
  }
};
</script>
